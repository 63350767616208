<template>
    <div id="teamwork">
        <div class="admin_main_block">
            <div class="user_temp_title">其他合作</div>
            <div class="admin_table_main">
                <div class="page_main">
                    <p><span style="font-weight: bold; font-size: large;">1 想要为平台供货&nbsp;</span></p>
                    <p><span style="font-weight: bold; font-size: large;"><br></span></p>
                    <p><span style="font-weight: bold; font-size: large;">2 自有商品申请入仓</span></p>
                    <p><span style="font-weight: bold; font-size: large;"><br></span></p>
                    <p><span style="font-weight: bold; font-size: large;">3 想让平台采货&nbsp;</span></p>
                    <p><span style="font-weight: bold; font-size: large;"><br></span></p>
                    <p><span style="font-weight: bold; font-size: large;">联系我们</span></p>
                    <p><span style="color: rgb(194, 79, 74);"><br></span></p>
                    <h1>
                        <span style="background-color: rgb(255, 255, 255); color: rgb(194, 79, 74);">
                            <span style="font-size: large;">电话/微信：</span>13665768848
                        </span>
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            
        }
    },
    created(){},
    mounted(){},
    watch: {},
    methods: {}
}
</script>

<style lang="scss" scoped>
#teamwork{
    .admin_main_block{
        background: #fff;
        width: 100%;
        box-sizing: border-box;
        min-height: 855px;
        border-radius: 4px;
        padding: 20px;
        .user_temp_title{
            margin: auto;
            padding-left: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;
            height: 25px;
            margin-bottom: 20px;
            font-weight: bold;
        }
    }
}
</style>